import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { SEARCH_CAREPLAN_TEMPLATES } from "graphql/SearchQueries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import CareplanTable from "components/TemplateSearchTables/CareplanTable";
import CareplanReviewTable from "components/TemplateSearchTables/CareplanReviewTable";
import SearchField from "components/formFields/SearchField";
import { Grid, Typography } from "@mui/material";
import Tooltip from "components/base/Tooltip";
import { colors } from "@lumonus/gc-ui";
import DebouncedButton from "components/base/DebouncedButton";
import { CREATE_TEMPLATE } from "graphql/TemplateQueries";
import {
  GET_DIAGNOSIS_CATEGORIES,
  GET_REGIONS,
  GET_SUBREGIONS,
} from "graphql/ListDataQueries";
import { useHistory } from "react-router-dom";
import SearchMultiSelectField from "components/formFields/SearchMultiSelectField";
import CheckboxGroupField from "components/formFields/CheckboxGroupField";
import SearchFilter from "components/SearchFilter/SearchFilter";
import { shared, getNationalName, DEFAULT_SEARCH_ROWS } from "i18n/constants";
import styled from "styled-components";
import { canUserCreate } from "shared/utils";
import UserContext from "contexts/UserContext";
import {
  ACTIVE,
  INACTIVE,
  TUMOUR_STREAMS,
  ACTIVE_STATUS,
  REGIONS,
  SUBREGIONS,
  StyledMenuItem,
  activeStatusToBoolean,
} from "./searchUtils";
import { StandardPaddedContainer } from "components/CommonStyledComponents";
import Button from "components/base/Button";

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  color: ${colors.neutral.darkGrey};
  padding: 10px 16px 6px 16px;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  background-color: transparent;
  margin-right: 10px;
  margin-left: 10px;
  white-space: nowrap;
  border-bottom: ${(props) =>
    props.$isActive && `4px solid ${colors.primary.base}`};
  color:  ${(props) =>
    props.$isActive && `${colors.neutral.black} !important`});

  &:hover {
    text-decoration: none;
    color: ${colors.neutral.black};
  }
`;

const CareplanSearch = () => {
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);

  const isActiveItem = (itemLocation: string): boolean =>
    location.pathname.startsWith(itemLocation);

  const DEFAULT_REGIONS = user?.regions?.map(
    (r: { name: string }): string => r.name,
  );
  const defaultRegions = JSON.stringify(DEFAULT_REGIONS);
  const defaultActiveStatus = JSON.stringify([ACTIVE]);

  const [searchResultsLength, setSearchResultsLength] = useState<number>(0);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [filterChange, setFilterChange] = useState<boolean>(false);
  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [selectedSubregions, setSelectedSubregions] = useState(
    JSON.parse(sessionStorage.getItem(SUBREGIONS) || "[]"),
  );

  const [bulkEditActive, setBulkEditActive] = useState<boolean>(false);
  const [bulkEditList, setBulkEditList] = useState<any[]>([]);

  const [selectedTumourStreams, setSelectedTumourStreams] = useState(
    JSON.parse(sessionStorage.getItem(TUMOUR_STREAMS) || "[]"),
  );
  const [selectedActiveStatus, setSelectedActiveStatus] = useState(
    JSON.parse(sessionStorage.getItem(ACTIVE_STATUS) || defaultActiveStatus),
  );
  // session can contain regions that previously existed but are no longer available
  // eg. login as AU user and then login as UK user then the AU region will still be in session
  const filteredSessionRegions =
    JSON.parse(sessionStorage.getItem(REGIONS) || "[]").filter(
      (region: string) => DEFAULT_REGIONS.includes(region),
    ) || DEFAULT_REGIONS;
  const [selectedRegions, setSelectedRegions] = useState(
    filteredSessionRegions.length !== 0
      ? filteredSessionRegions
      : DEFAULT_REGIONS,
  );
  const createEnabled = canUserCreate(user, selectedRegions);

  const [getSearchTerm, result] = useLazyQuery(SEARCH_CAREPLAN_TEMPLATES, {
    fetchPolicy: "network-only",
  });

  const { data: subregions, loading: subregionsLoading } = useQuery(
    GET_SUBREGIONS,
    {
      fetchPolicy: "cache-first",
      variables: { regions: selectedRegions },
    },
  );
  const { loading: loadingTumourStreams, data: tumourStreams } = useQuery(
    GET_DIAGNOSIS_CATEGORIES,
    {
      fetchPolicy: "cache-first",
    },
  );
  const { data: regions } = useQuery(GET_REGIONS, {
    fetchPolicy: "cache-first",
  });

  const tumourStreamOptions =
    tumourStreams?.diagnosisCategoryList
      ?.map((ts: { name: string }): string => ts.name)
      .sort() || [];
  const subregionOptions =
    subregions?.subregionList
      ?.map((s: { name: string }): string => s.name)
      .sort() || [];
  const regionOptions =
    regions?.regionList?.map((r: { name: string }): string => r.name).sort() ||
    [];

  const [createTemplate] = useMutation(CREATE_TEMPLATE, {
    variables: { isRo: true, isMo: false, isTemporary: false },
    onCompleted: (data) => {
      if (data?.createTemplate?.template) {
        const templateId = data.createTemplate.template.id;
        history.push(`/template/${templateId}/information`);
      }
    },
  });

  useEffect(() => {
    const storedBulkEditList = sessionStorage.getItem("bulkEditList");
    if (storedBulkEditList) {
      setBulkEditList(JSON.parse(storedBulkEditList));
    }
    const storedBulkEditActive = sessionStorage.getItem("bulkEditActive");
    if (storedBulkEditActive) {
      setBulkEditActive(JSON.parse(storedBulkEditActive));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("bulkEditList", JSON.stringify(bulkEditList));
  }, [bulkEditList]);

  useEffect(() => {
    sessionStorage.setItem("bulkEditActive", JSON.stringify(bulkEditActive));
  }, [bulkEditActive]);

  useEffect(() => {
    sessionStorage.setItem(
      TUMOUR_STREAMS,
      JSON.stringify(selectedTumourStreams),
    );
  }, [selectedTumourStreams]);

  useEffect(() => {
    sessionStorage.setItem(ACTIVE_STATUS, JSON.stringify(selectedActiveStatus));
  }, [selectedActiveStatus]);

  useEffect(() => {
    sessionStorage.setItem(REGIONS, JSON.stringify(selectedRegions));
    if (!subregionsLoading) {
      const subregionsIntersection = subregionOptions.filter((value: string) =>
        selectedSubregions.includes(value),
      );
      setSelectedSubregions(subregionsIntersection);
    }
    // eslint-disable-next-line
  }, [selectedRegions]);

  useEffect(() => {
    sessionStorage.setItem(SUBREGIONS, JSON.stringify(selectedSubregions));
  }, [selectedSubregions]);

  useEffect(() => {
    if (result.data) {
      setSearchResultsLength(parseInt(result.data.templateFilter.totalLength));
      const templates = result.data.templateFilter.templates.length
        ? result.data.templateFilter.templates
        : [];
      if (!searchResults.length || !templates.length || filterChange) {
        setSearchResults(templates);
      } else {
        const templateIds = new Set(
          searchResults.map((template) => template.id),
        );
        const combinedResults = [
          ...searchResults,
          ...result.data.templateFilter.templates.filter(
            (template: any) => !templateIds.has(template.id),
          ),
        ];
        setSearchResults(combinedResults);
      }
    }
    // eslint-disable-next-line
  }, [result]);

  // Perform a search when a drop down filter changes
  useEffect(() => {
    // Ckear results before replacing with new filtered data
    setSearchResults([]);
    getTemplateResults(currentSearch);
    // eslint-disable-next-line
  }, [
    selectedTumourStreams,
    selectedActiveStatus,
    selectedRegions,
    selectedSubregions,
  ]);

  const getTemplateResults = (
    searchTerm: string,
    pageNumber = 0,
    rowsPerPage = 0,
  ) => {
    const manualSearch = searchTerm ? searchTerm : "";
    const includeTreatment = isActiveItem("/review");
    let start: number | null;
    let limit: number | null;
    const getSearchResults = (): void => {
      setCurrentSearch(searchTerm);
      getSearchTerm({
        variables: {
          searchTerm: manualSearch,
          tumourStreams: selectedTumourStreams,
          activeStatus: selectedActiveStatus.map((status: string) =>
            activeStatusToBoolean(status),
          ),
          subregions: selectedSubregions,
          regions: selectedRegions,
          start: start ? start : null,
          limit: limit ? limit : null,
          isMo: false,
          isRo: true,
          isTemporary: false,
          includeTreatment: includeTreatment,
        },
      });
    };

    // Not been provided pagination values so use defaults (initial load)
    if ((!pageNumber && !rowsPerPage) || filterChange) {
      start = null;
      limit = DEFAULT_SEARCH_ROWS;
      setFilterChange(false);
      getSearchResults();
      return;
    }

    // Otherwise get values with pagination values
    start = rowsPerPage * pageNumber;
    limit = rowsPerPage * pageNumber + rowsPerPage;
    setFilterChange(false);
    getSearchResults();
  };

  if (loadingTumourStreams) {
    return <div>Loading</div>;
  }

  const userRegions = user?.regions?.map((r: any) => r.name);

  const enableBulkEdit =
    selectedTumourStreams.length === 1 &&
    selectedRegions.length === 1 &&
    userRegions.includes(selectedRegions[0]);
  const tooltipTitle =
    selectedTumourStreams.length !== 1
      ? "Select one 'Tumour Stream' via the filter on the left to enable bulk edit. Note: Bulk edit is unavailable if more than one 'Tumour Stream' filter is selected"
      : "Bulk edit is only available if one region is selected and if the selected region matches your region";

  const filterDisabledTooltipText =
    "You cannot change this while in bulk edit mode";

  return (
    <StandardPaddedContainer>
      <Typography variant="h4" gutterBottom>
        Careplan Templates
      </Typography>

      <Grid
        container
        spacing={1}
        style={{ marginBottom: "5px", flexWrap: "unset" }}
        alignItems="center"
      >
        <Grid item>
          <SearchFilter>
            {bulkEditActive ? (
              <StyledMenuItem>
                <Tooltip
                  className="filterDisabled"
                  title={filterDisabledTooltipText}
                >
                  <div>
                    <SearchMultiSelectField
                      data-test-id="tumour-stream-filter"
                      disabled={true}
                      selectedOptionsUpdated={setSelectedTumourStreams}
                      resetSearchResults={() => setFilterChange(true)}
                      selectedOptions={selectedTumourStreams}
                      options={[]}
                      placeholder="Tumour Stream"
                      allSelectedLabel={shared.allTumorStreams}
                    />
                  </div>
                </Tooltip>
              </StyledMenuItem>
            ) : (
              <StyledMenuItem data-test-id="tumour-stream-filter">
                <SearchMultiSelectField
                  data-test-id="tumour-stream-filter"
                  selectedOptionsUpdated={setSelectedTumourStreams}
                  resetSearchResults={() => setFilterChange(true)}
                  selectedOptions={selectedTumourStreams}
                  options={tumourStreamOptions}
                  placeholder="Tumour Stream"
                  allSelectedLabel={shared.allTumorStreams}
                />
              </StyledMenuItem>
            )}
            {bulkEditActive ? (
              <Tooltip title={filterDisabledTooltipText}>
                <StyledMenuItem>
                  <SearchMultiSelectField
                    selectedOptionsUpdated={(values: any) => {
                      setSelectedRegions(values);
                      setSelectedSubregions([]);
                    }}
                    disabled={true}
                    resetSearchResults={() => setFilterChange(true)}
                    selectedOptions={selectedRegions}
                    options={regionOptions}
                    placeholder="Region"
                    allSelectedLabel={shared.allRegions}
                  />
                </StyledMenuItem>
              </Tooltip>
            ) : (
              <StyledMenuItem>
                <SearchMultiSelectField
                  selectedOptionsUpdated={(values: any) => {
                    setSelectedRegions(values);
                    setSelectedSubregions([]);
                  }}
                  resetSearchResults={() => setFilterChange(true)}
                  selectedOptions={selectedRegions}
                  options={regionOptions}
                  placeholder="Region"
                  allSelectedLabel={shared.allRegions}
                />
              </StyledMenuItem>
            )}
            {subregionOptions.length ? (
              <StyledMenuItem>
                <SearchMultiSelectField
                  selectedOptionsUpdated={setSelectedSubregions}
                  resetSearchResults={() => setFilterChange(true)}
                  selectedOptions={selectedSubregions}
                  options={subregionOptions}
                  placeholder="Subregion"
                  allSelectedLabel={
                    selectedRegions.length === 1
                      ? getNationalName(selectedRegions[0])
                      : "All"
                  }
                />
              </StyledMenuItem>
            ) : null}
            <StyledMenuItem>
              <CheckboxGroupField
                selectedOptionsUpdated={setSelectedActiveStatus}
                selectedOptions={selectedActiveStatus}
                options={[ACTIVE, INACTIVE]}
                placeholder="Template Status"
              />
            </StyledMenuItem>
          </SearchFilter>
        </Grid>
        <Grid item>
          <SearchField
            dataTestId="template-search"
            placeholderText="Search templates..."
            onEnter={(text) => {
              if (text !== currentSearch) {
                setSearchResults([]);
                setFilterChange(true);
                getTemplateResults(text);
              }
            }}
          />
        </Grid>
        {isActiveItem("/review") && (
          <Grid item>
            <StyledLink
              to="/review"
              $isActive={
                isActiveItem("/review") && !isActiveItem("/review/sim")
              }
            >
              Prescription
            </StyledLink>
          </Grid>
        )}
        {isActiveItem("/review") && (
          <Grid item>
            <StyledLink
              data-test-id="sim-tab"
              to="/review/sim"
              $isActive={isActiveItem("/review/sim")}
            >
              Sim and OARs
            </StyledLink>
          </Grid>
        )}
        <Grid item container justifyContent="flex-end">
          {isActiveItem("/search") && (
            <DebouncedButton
              Component={Button}
              color="primary"
              mode="outlined"
              delay={5000}
              data-test-id="create-template"
              disabled={!createEnabled || selectedRegions.length !== 1}
              tooltip={
                selectedRegions.length !== 1 &&
                "Select one 'Region' via the filter on the left to enable template addition. Note: template addition is unavailable if more than one 'Region' filter is selected"
              }
              onClick={() => {
                const region = selectedRegions[0];
                createTemplate({
                  variables: {
                    region: region,
                    isRo: true,
                    isMo: false,
                    isTemporary: false,
                  },
                });
              }}
            >
              Create New Template
            </DebouncedButton>
          )}

          {isActiveItem("/review") && (
            <>
              {!bulkEditActive &&
                (enableBulkEdit ? (
                  <Button
                    mode="outlined"
                    title="Bulk Edit"
                    color="primary"
                    data-test-id="bulk-edit-start"
                    disabled={false}
                    onClick={() => {
                      setBulkEditActive(true);
                    }}
                  >
                    Bulk Edit
                  </Button>
                ) : (
                  <Tooltip className="tooltipTitle" title={tooltipTitle}>
                    <div style={{ cursor: "not-allowed" }}>
                      <Button
                        style={{ pointerEvents: "none" }}
                        title="Bulk Edit"
                        outlined={true}
                        data-test-id="bulk-edit-start"
                        disabled={true}
                      >
                        Bulk Edit
                      </Button>
                    </div>
                  </Tooltip>
                ))}
            </>
          )}
        </Grid>
      </Grid>
      {isActiveItem("/search") && (
        <CareplanTable
          filterChange={filterChange}
          getCareplans={getTemplateResults}
          careplansLength={searchResultsLength}
          careplans={searchResults}
          loading={result.loading}
        />
      )}
      {isActiveItem("/review") && (
        <CareplanReviewTable
          bulkEditActive={bulkEditActive}
          setBulkEditActive={setBulkEditActive}
          bulkEditList={bulkEditList}
          setBulkEditList={setBulkEditList}
          filterChange={filterChange}
          getCareplans={getTemplateResults}
          careplansLength={searchResultsLength}
          careplans={searchResults}
          loading={result.loading}
          selectedRegion={selectedRegions?.[0]}
        />
      )}
    </StandardPaddedContainer>
  );
};

export default CareplanSearch;
