import React from "react";
import { List, ListItem, ListItemText, Grid } from "@mui/material";
import moment from "moment";
import styled from "styled-components";
import { prettyDate } from "shared/utils";
import { getNationalName, cycles } from "../../i18n/constants";
import EvidenceLink from "components/base/EvidenceLink";
import { TemplateInterface } from "interfaces/templates";

const StyledListItemText = styled(ListItemText)`
  font-weight: bold;
`;

interface KeyValueViewProps {
  dataKey: string;
  value: string | number | JSX.Element;
}

interface TemplateDetailsProps {
  template: TemplateInterface;
}

const KeyValueView = (props: KeyValueViewProps): JSX.Element => {
  return (
    <ListItem style={{ paddingTop: "2px", paddingBottom: "2px" }}>
      <Grid item xs>
        <StyledListItemText
          disableTypography={true}
          data-test-id={`${props.dataKey}-key`}
          primary={props.dataKey}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs>
        <ListItemText
          data-test-id={`${props.dataKey}-value`}
          primary={props?.value}
        />
      </Grid>
    </ListItem>
  );
};

const TemplateDetails = (props: TemplateDetailsProps) => {
  const template = props.template;
  const regions = template?.regions?.map((region: any): string => region.name);
  const regionsText = regions?.join(", ");
  const subregionText = template?.subregions
    ?.map((subregion: any): string => subregion.name)
    .join(", ");
  const showSubregions = template?.subregions?.length > 0;
  const { tumourStream, isMo, isRo } = template;
  const tumourStreamText = tumourStream
    ? `${tumourStream.anatomicSiteNum} - ${tumourStream.name}`
    : "";
  const createdDate = template
    ? moment(template.createdAt).format("DD/MM/YYYY")
    : "";
  const createdBy = template.createdBy === "None" ? "-" : template.createdBy;
  const approvedBy = template.approvedBy === "None" ? "-" : template.approvedBy;

  const evidenceLink: string | JSX.Element = (
    <EvidenceLink template={template}></EvidenceLink>
  );

  return (
    <List>
      <KeyValueView dataKey="ID" value={template.id} />
      <KeyValueView dataKey="Tumour Stream" value={tumourStreamText} />
      {isRo && (
        <KeyValueView dataKey="Site Filter" value={template.siteFilter} />
      )}
      {isRo && (
        <KeyValueView dataKey="Dose Filter" value={template.doseFilter} />
      )}
      {isRo && (
        <KeyValueView
          dataKey="Treatment Technique"
          value={template.techniqueFilter}
        />
      )}
      {isMo && <KeyValueView dataKey="Evidence" value={evidenceLink} />}
      {isMo && (
        <KeyValueView
          dataKey="Number of Cycles"
          value={cycles(template?.continuous, template?.numberOfCycles)}
        />
      )}
      {isMo && (
        <KeyValueView dataKey="Days per Cycle" value={template.daysPerCycle} />
      )}
      <KeyValueView
        dataKey="Region"
        value={regions.length === 0 ? "Global" : regionsText}
      />
      {showSubregions && (
        <KeyValueView
          dataKey="Subregions"
          value={
            template?.isNational
              ? getNationalName(regions.pop())
              : subregionText
          }
        />
      )}
      <KeyValueView dataKey="Created by" value={createdBy} />
      <KeyValueView dataKey="Created date" value={createdDate} />
      <KeyValueView dataKey="Approved by" value={approvedBy} />
      <KeyValueView
        dataKey="Last edited"
        value={template.editedAt ? prettyDate(template.editedAt) : "-"}
      />
    </List>
  );
};

export default TemplateDetails;
