import React from "react";
import { useParams } from "react-router-dom";
import { Formik, Field, Form } from "formik";

import { useQuery, useMutation } from "@apollo/client";
import Divider from "@mui/material/Divider";

import FormPage from "components/base/FormPage";
import InputField from "components/formFields/InputField";
import ToggleButtonGroupField from "components/formFields/ToggleButtonGroupField";
import SelectField from "components/formFields/SelectField";
import MultiSelectField from "components/formFields/MultiSelectField";
import { setBolusValues } from "shared/utils";

import { yesNoOptions, simulationContent } from "i18n/constants";
import {
  GET_SITE_GROUP_TEMPLATE,
  UPDATE_TEMPLATE_GROUP_FIELDS,
} from "../graphql/SiteGroupQueries";
import { SelectOptionsInterface } from "interfaces/common";

interface Props {
  selectOptions: SelectOptionsInterface;
  disableEditing: boolean;
  groupIds: any[];
}

interface ParamTypes {
  templateId: string;
  groupId: string;
}

export const simulationPageBack = (
  templateId: string,
  groupId: string,
  groupIds: any,
): string => {
  const siteIndex: number = groupIds.indexOf(groupId);
  const back =
    siteIndex === 0
      ? `/template/${templateId}/information`
      : `/template/${templateId}/simulation/${groupIds[siteIndex - 1]}`;
  return back;
};

export const simulationPageNext = (
  templateId: string,
  groupId: string,
  groupIds: any,
  firstSiteId: any,
): string => {
  const siteIndex: number = groupIds.indexOf(groupId);
  const next =
    siteIndex < groupIds.length - 1
      ? `/template/${templateId}/simulation/${groupIds[siteIndex + 1]}`
      : `/template/${templateId}/prescription/${firstSiteId}`;
  return next;
};

const [simulationFields] = [simulationContent.fields];

const Simulation = ({ selectOptions, disableEditing, groupIds }: Props) => {
  const { groupId } = useParams<ParamTypes>();
  const siteIndex: number = groupIds.indexOf(groupId);
  const { heading, fields } = simulationContent;

  const [updateTemplateGroupFields] = useMutation(UPDATE_TEMPLATE_GROUP_FIELDS);
  const { data, loading, error } = useQuery(GET_SITE_GROUP_TEMPLATE, {
    fetchPolicy: "network-only",
    variables: { groupId },
  });

  // Needed before data manipulation to prevent stale graphql data changing values
  if (loading) return <div>Loading ...</div>;
  if (error) return <div>Error, please contact support</div>;

  const groupRules = data?.siteGroupTemplate?.siteGroupRules || [];
  const initActiveFields = groupRules.reduce(
    (simulationFields: any, fieldDetails: any) => {
      const { field, isShown } = fieldDetails;
      simulationFields[field.name] = isShown;
      return simulationFields;
    },
    {},
  );
  const defaultValues = groupRules.reduce(
    (fieldValues: any, fieldDetails: any) => {
      const { field, defaultValue } = fieldDetails;
      const value = JSON.parse(defaultValue);
      if (!simulationContent.textFields.includes(field.name)) {
        fieldValues[field.name] = ["true", "false", "null"].includes(value)
          ? JSON.parse(value)
          : value;
      } else {
        fieldValues[field.name] = value;
      }
      return fieldValues;
    },
    {},
  );

  Object.keys(simulationFields)
    .filter(
      (fieldName: any) =>
        !Object.prototype.hasOwnProperty.call(defaultValues, fieldName),
    )
    .map((fieldName: any) => (defaultValues[fieldName] = ""));

  const updateBolusActiveValues = (isActive: boolean): void => {
    const { bLocation, bThickness, bThicknessCustom, bIs3d } = setBolusValues(
      groupId,
      isActive,
      defaultValues,
    );
    updateTemplateGroupFields({ variables: bLocation });
    updateTemplateGroupFields({ variables: bThickness });
    updateTemplateGroupFields({ variables: bThicknessCustom });
    updateTemplateGroupFields({ variables: bIs3d });
  };

  const handleMutation = (
    fieldName: string,
    value: any,
    isActive?: boolean,
  ) => {
    let updateVariables = { groupId, fieldName, defaultValue: value };
    if (typeof isActive === "boolean") {
      if (fieldName === "Bolus") {
        updateBolusActiveValues(isActive);
      }

      updateVariables = Object.assign(
        { isShown: isActive, isActive },
        updateVariables,
      );
    }
    if (fieldName === "CT_Simulation") {
      updateTemplateGroupFields({
        variables: {
          groupId,
          fieldName: "Contrast_Required",
          defaultValue: null,
          isShown: false,
          isActive: false,
        },
      });
      return;
    }

    if (fieldName === "Bolus_Location_Type") {
      // Copy the Location type to the Location field if its not 'Specify location'
      updateTemplateGroupFields({
        variables: {
          defaultValue: value === "Specify location" ? "" : value,
          fieldName: "Bolus_Location",
          groupId,
        },
      });
      return;
    }
    if (fieldName === "Immobilisation") {
      updateTemplateGroupFields({
        variables: {
          groupId,
          fieldName: "Immobilisation",
          defaultValue: JSON.stringify(value), // value is an array
          isShown: isActive,
        },
      });
      return;
    }
    updateTemplateGroupFields({ variables: updateVariables });
  };

  return (
    <>
      {data?.siteGroupTemplate?.siteGroupRules && (
        <Formik
          initialValues={defaultValues}
          enableReinitialize
          onSubmit={() => {}}
        >
          {({ values }) => {
            return (
              <Form>
                <FormPage
                  heading={heading}
                  siteHeading={`Site Group ${siteIndex + 1}`}
                >
                  <Field
                    component={ToggleButtonGroupField}
                    id="ct-simulation-id"
                    name="CT_Simulation"
                    label={fields.CT_Simulation}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={!disableEditing}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="contrast-required-id"
                    name="Contrast_Required"
                    label={fields.Contrast_Required}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={
                      initActiveFields.Contrast_Required && values.CT_Simulation
                    }
                    toggleActive={values.CT_Simulation}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="ro-required-id"
                    name="RO_Required"
                    label={fields.RO_Required}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={!disableEditing}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={SelectField}
                    id="extend-of-ct-id"
                    name="Extent_Of_CT"
                    label={fields.Extent_Of_CT}
                    options={selectOptions.extentOfCT}
                    handleMutation={handleMutation}
                    isActive={values.CT_Simulation}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={InputField}
                    id="extent-defined-id"
                    name="Extent_Defined"
                    label={fields.Extent_Defined}
                    handleMutation={handleMutation}
                    isActive={
                      values.Extent_Of_CT?.toLowerCase() ===
                        "as per template" && values.CT_Simulation
                    }
                    multiline
                    readOnly={disableEditing}
                  />
                  <Field
                    component={SelectField}
                    id="patient-position-id"
                    name="Patient_Position"
                    label={fields.Patient_Position}
                    options={selectOptions.patientPosition}
                    handleMutation={handleMutation}
                    isActive={!disableEditing}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={InputField}
                    id="position-other-id"
                    name="Position_Other"
                    label={fields.Position_Other}
                    handleMutation={handleMutation}
                    isActive={
                      values.Patient_Position?.toLowerCase() ===
                      "as per template"
                    }
                    multiline
                    readOnly={disableEditing}
                  />
                  <Field
                    component={MultiSelectField}
                    id="immobilisation-id"
                    name="Immobilisation"
                    label={fields.Immobilisation}
                    options={selectOptions.immobilisation}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Immobilisation}
                    toggleActive={true}
                    readOnly={disableEditing}
                    selectedOptions={values.Immobilisation || []}
                    multiple
                    isArrayValues
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="mask-id"
                    name="Mask"
                    label={fields.Mask}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Mask}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={SelectField}
                    id="stomach-id"
                    name="Stomach"
                    label={fields.Stomach}
                    options={selectOptions.stomach}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Stomach}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="abdomen-compression-id"
                    name="Abdomen_Compression"
                    label={fields.Abdomen_Compression}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Abdomen_Compression}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={SelectField}
                    id="bladder-id"
                    name="Bladder"
                    label={fields.Bladder}
                    options={selectOptions.bladder}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Bladder}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="bowel-prep-id"
                    name="Bowel_Prep"
                    label={fields.Bowel_Prep}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Bowel_Prep}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="gold-seeds-id"
                    name="Gold_Seeds"
                    label={fields.Gold_Seeds}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Gold_Seeds}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="vaginal-tampon-id"
                    name="Vaginal_Tampon"
                    label={fields.Vaginal_Tampon}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Vaginal_Tampon}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="space-oar-id"
                    name="Spacer_Gel"
                    label={fields.Spacer_Gel}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Spacer_Gel}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={InputField}
                    id="tongue-position-id"
                    name="Tongue_Position"
                    label={fields.Tongue_Position}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Tongue_Position}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="mouthpiece-id"
                    name="Mouthpiece"
                    label={fields.Mouthpiece}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Mouthpiece}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="gating-id"
                    name="Gating"
                    label={fields.Gating}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Gating}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="four-dct-id"
                    name="Four_DCT"
                    label={fields.Four_DCT}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Four_DCT}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="breath-hold-id"
                    name="Breath_Hold"
                    label={fields.Breath_Hold}
                    options={selectOptions.breathHold}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Breath_Hold}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Divider />

                  {/** Bolus */}
                  <Field
                    component={ToggleButtonGroupField}
                    id="bolus-id"
                    name="Bolus"
                    label={fields.Bolus}
                    options={yesNoOptions}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Bolus}
                    toggleActive={true}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="is-3d-bolus-id"
                    name="Is_3d_Bolus"
                    label={fields.Is_3d_Bolus}
                    options={selectOptions.is3dBolus}
                    handleMutation={handleMutation}
                    isActive={initActiveFields.Is_3d_Bolus}
                    toggleActive={true}
                    readOnly={disableEditing || !initActiveFields.Bolus}
                  />
                  <Field
                    component={ToggleButtonGroupField}
                    id="bolus-location-type-id"
                    name="Bolus_Location_Type"
                    label={fields.Bolus_Location_Type}
                    options={selectOptions.bolusLocationType}
                    handleMutation={handleMutation}
                    isActive={values.Bolus}
                    readOnly={disableEditing}
                  />
                  {values.Bolus_Location_Type === "Specify location" && (
                    <Field
                      component={InputField}
                      id="bolus-location-id"
                      name="Bolus_Location"
                      label={fields.Bolus_Location}
                      handleMutation={handleMutation}
                      isActive={
                        values.Bolus &&
                        values.Bolus_Location_Type === "Specify location"
                      }
                      readOnly={disableEditing}
                    />
                  )}
                  <Field
                    component={SelectField}
                    id="bolus-thickness-id"
                    name="Bolus_Thickness"
                    label={fields.Bolus_Thickness}
                    options={selectOptions.bolusThickness}
                    handleMutation={handleMutation}
                    isActive={values.Bolus}
                    readOnly={disableEditing}
                  />
                  {values.Bolus_Thickness === "Custom" && (
                    <Field
                      component={InputField}
                      id="bolus-thickness-custom-id"
                      name="Bolus_Thickness_Custom"
                      label={fields.Bolus_Thickness_Custom}
                      handleMutation={handleMutation}
                      isActive={
                        values.Bolus && values.Bolus_Thickness === "Custom"
                      }
                      readOnly={disableEditing}
                    />
                  )}
                  <Field
                    component={SelectField}
                    id="Bolus-Frequency-id"
                    name="Bolus_Frequency"
                    label={fields.Bolus_Frequency}
                    options={selectOptions.bolusFrequency}
                    handleMutation={handleMutation}
                    isActive={!disableEditing}
                    readOnly={disableEditing}
                  />
                  <Divider />
                  <Field
                    component={InputField}
                    id="extra-ct-markers-id"
                    name="Extra_CT_Markers"
                    label={fields.Extra_CT_Markers}
                    handleMutation={handleMutation}
                    isActive={!disableEditing}
                    readOnly={disableEditing}
                  />
                  <Field
                    component={InputField}
                    id="additional-sim-info-id"
                    name="Additional_Sim_Info"
                    label={fields.Additional_Sim_Info}
                    handleMutation={handleMutation}
                    isActive={!disableEditing}
                    multiline
                    readOnly={disableEditing}
                  />
                </FormPage>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default Simulation;
