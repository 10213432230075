import React from "react";
import { FieldProps, getIn } from "formik";
import {
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";

import Select from "components/base/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormRow from "components/base/FormRow";
import styled from "styled-components";
import { colors } from "@lumonus/gc-ui";
import InvalidMessage from "components/base/InvalidMessage";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTheme } from "@mui/material/styles";

const StyledCheckbox = styled(Checkbox)`
  && {
    &.Mui-checked {
      color: ${(props) => (props.checked ? colors.primary.base : "white")};
    }
  }
`;

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  padding: 0px !important;
`;

const StyledChip = styled(Chip)`
  && {
    margin: 2px;
  }
`;

const StyledFormControl = styled(FormControl)`
  // && {
  //   min-width: unset;
  // }
  width: 100%;
`;

interface Props extends FieldProps {
  label: string;
  options: any[];
  handleMutation: any;
  noEmptyOption: boolean;
  toggleActive?: boolean;
  isActive: boolean;
  readOnly: boolean;
  placeholder: string;
  allSelected?: boolean;
  allSelectedLabel?: string;
  labelXs?: any;
  isArrayValues?: boolean;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const MultiSelectField = ({
  field,
  form,
  label,
  options,
  handleMutation,
  isActive,
  toggleActive,
  readOnly,
  placeholder = "Please select",
  allSelected,
  allSelectedLabel,
  labelXs,
  isArrayValues,
}: Props) => {
  const { errors, touched, setFieldValue } = form;
  const { name = "", value = [] } = field;
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);
  const [disabled, setDisabled] = React.useState<boolean>(!isActive);
  const theme = useTheme();

  const handleChange = (value: any) => {
    const selectedValues = value ? value.map((val: any) => val.value) : [];
    setFieldValue(name, selectedValues);
    handleMutation &&
      handleMutation(
        name,
        isArrayValues ? selectedValues : JSON.stringify(selectedValues),
      );
  };

  const handleDisable = (value: boolean) => {
    setDisabled(!value);
    handleMutation && handleMutation(name, "", value);
  };

  // When isActive changes, update the disabled state
  React.useEffect(() => {
    if (isActive) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isActive]);

  return (
    <FormRow
      label={label}
      disabled={disabled}
      name={name}
      toggleActive={toggleActive}
      handleDisable={handleDisable}
      readOnly={readOnly}
      labelXs={labelXs}
      errorChildren={
        Boolean(fieldTouched) &&
        Boolean(fieldError) && <InvalidMessage text={fieldError} />
      }
    >
      <StyledFormControl variant="outlined">
        <Autocomplete
          id={`${name}-select-id`}
          data-testid={`${name}-select-id`}
          multiple
          fullWidth
          disabled={!isActive || readOnly}
          disableCloseOnSelect
          limitTags={options.length}
          options={options}
          value={
            value === ""
              ? []
              : value?.map((val: string) =>
                  options.find((opt) => opt.value === val),
                )
          }
          onChange={(_event, value) => {
            handleChange(value);
          }}
          getOptionLabel={(option) => option.label}
          getOptionKey={(option) => option.value}
          renderInput={(params) =>
            allSelected === true && allSelectedLabel ? (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <ChipsWrapper>
                      <StyledChip
                        key={allSelectedLabel}
                        label={allSelectedLabel}
                      />
                    </ChipsWrapper>
                  ),
                }}
                size="small"
                placeholder={value.length > 0 ? "" : placeholder}
              />
            ) : (
              <TextField
                {...params}
                size="small"
                placeholder={value.length > 0 ? "" : placeholder}
              />
            )
          }
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props as { key: string };

            return (
              <li
                key={key}
                {...optionProps}
                style={{ padding: "0px", margin: "0px" }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.label}
              </li>
            );
          }}
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            maxWidth: "400px",
            minWidth: "250px",
            minHeight: "56px",
            "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input":
              {
                padding: "2.5px 4px 2.5px 0px",
              },
            "& .MuiOutlinedInput-root.Mui-disabled": {
              backgroundColor: theme.palette.grey[100],
            },
            "& .MuiOutlinedInput-input.Mui-disabled": {
              WebkitTextFillColor: theme.palette.text.primary,
            },
            "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
              opacity: 1,
              color: theme.palette.grey[600],
            },
            ".MuiInputBase-root": {
              minHeight: "56px",
              "&:hover": {
                backgroundColor: theme.palette.grey[100],
              },
            },
            'input[type="number"]::-webkit-inner-spin-button': {
              "-webkit-appearance": "none",
              margin: 0,
            },
            'input[type="number"]::-webkit-outer-spin-button': {
              "-webkit-appearance": "none",
              margin: 0,
            },
            ".MuiFormHelperText-root": {
              marginLeft: 0,
            },
            "& .MuiChip-label": {
              height: "24px",
            },
            "& .MuiChip-deleteIcon": {
              color: theme.palette.primary.dark,
              width: "16px",
              height: "16px",
              "&:hover": {
                color: theme.palette.primary.light,
              },
            },
          }}
        />
      </StyledFormControl>
    </FormRow>
  );
};

export default MultiSelectField;
