import React, { useEffect } from "react";
import { TableRow, TableBody, TablePagination, Link } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  StyledPaper,
  StyledTableHead,
  StyledTableContainer,
  StyledTable,
  SmallTableCell,
  MiniTableCell,
  MediumTableCell,
  LargeTableCell,
} from "components/TableStyles";
import {
  directionalComparitor,
  prettyDate,
  sortArrayByComparitor,
} from "shared/utils";
import {
  TemplateColumnInterface,
  CareplanTemplateInterface,
} from "./TableInterfaces";
import { colors } from "@lumonus/gc-ui";
import { getNationalName } from "../../i18n/constants";
import {
  templateColumn,
  getCountryList,
  ROWS_PER_PAGE,
  StatusTrafficLight,
  ColorFromStatus,
  BeautifyStatus,
  PlaceholderRow,
} from "./tableUtils";

interface Props {
  careplans: CareplanTemplateInterface[];
  getCareplans(options: any, pageCount: number, rowsCount: number): void;
  careplansLength: number;
  filterChange: boolean;
  loading: boolean;
}

const CARE_PLAN_TABLE_COLUMNS: TemplateColumnInterface[] = [
  { label: "Tumour Stream", attribute: "tumourStreamName" },
  { label: "Template Name", attribute: "name" },
  { label: "Region", attribute: "regions" },
  { label: "Subregions", attribute: "subregions" },
  { label: "Status", attribute: "status" },
  { label: "Site Filter", attribute: "siteFilter" },
  { label: "Dose Filter", attribute: "doseFilter" },
  { label: "Treatment Technique", attribute: "techniqueFilter" },
  { label: "Last Modified", attribute: "editedAt" },
];

const careplanRow = (
  careplan: CareplanTemplateInterface,
  key: string,
  history: any,
): JSX.Element => {
  // Gray out inactive templates
  const backgroundStyling = {
    backgroundColor: !careplan.isActive
      ? colors.neutral.lightestGrey
      : undefined,
    cursor: "pointer",
  };
  const cellStyling = {
    color: !careplan.isActive ? colors.neutral.darkGrey : colors.neutral.black,
  };

  return (
    <TableRow
      style={backgroundStyling}
      data-test-id={`search-results-${careplan.id}`}
      key={key}
    >
      <MediumTableCell style={cellStyling}>
        {careplan.tumourStreamName}
      </MediumTableCell>
      <LargeTableCell>
        <Link href={`/template/${careplan.id}`} rel="noopener noreferrer">
          {careplan.name}
        </Link>
        <br />
        {`ID: ${careplan.id}`}
      </LargeTableCell>
      <MiniTableCell style={cellStyling}>
        {getCountryList(careplan.regions)}
      </MiniTableCell>
      <SmallTableCell style={cellStyling}>
        {careplan?.isNational
          ? getNationalName(careplan.regions?.[0].name)
          : getCountryList(careplan.subregions)}
      </SmallTableCell>
      <MiniTableCell
        data-test-id={`template-${careplan.id}-status`}
        style={cellStyling}
      >
        <StatusTrafficLight
          color={
            careplan.isActive
              ? ColorFromStatus(careplan.status)
              : colors.destructive.base
          }
        >
          {careplan.isActive ? BeautifyStatus(careplan.status) : "Archived"}
        </StatusTrafficLight>
      </MiniTableCell>
      <MediumTableCell style={cellStyling}>
        {careplan.siteFilter}
      </MediumTableCell>
      <SmallTableCell style={cellStyling}>{careplan.doseFilter}</SmallTableCell>
      <MediumTableCell style={cellStyling}>
        {careplan.techniqueFilter}
      </MediumTableCell>
      <MediumTableCell style={cellStyling}>
        {prettyDate(careplan.editedAt || careplan.createdAt)}
      </MediumTableCell>
    </TableRow>
  );
};

const CareplanTable = ({
  getCareplans,
  careplans,
  careplansLength,
  filterChange,
  loading,
}: Props) => {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    ROWS_PER_PAGE[0],
  );
  const [page, setPage] = React.useState<number>(0);
  const [orderDirection, setOrderDirection] = React.useState<"asc" | "desc">(
    "asc",
  );
  const [orderBy, setOrderBy] = React.useState<string>("");

  useEffect(() => {
    if (filterChange) {
      setPage(0);
      handlePagination(0, false, 0);
    }
    // eslint-disable-next-line
  }, [filterChange]);

  const handleSortingCallback = (attribute: any) => {
    const isAsc = orderBy === attribute && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(attribute);
    setPage(0);
  };

  const handlePagination = (
    pageNumber: any,
    includesRowsPerPage: boolean,
    row: any,
  ) => {
    // If attempting to reach a page beyond current results amount, exit function
    if (pageNumber && row && pageNumber * row >= careplans.length) return;
    setPage(pageNumber);
    if (includesRowsPerPage) setRowsPerPage(row);
    const pageCount = pageNumber ? pageNumber : 0;
    const rowsCount = row ? row : rowsPerPage;
    getCareplans(null, pageCount, rowsCount);
  };

  return (
    <StyledPaper>
      <StyledTableContainer>
        <StyledTable stickyHeader data-test-id={"care-plan-table"}>
          <StyledTableHead>
            <TableRow>
              {CARE_PLAN_TABLE_COLUMNS.map((header, index) => {
                return templateColumn(
                  header,
                  `careplan-header-${index}`,
                  orderBy,
                  orderDirection,
                  handleSortingCallback,
                );
              })}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {careplans.length ? (
              sortArrayByComparitor(
                careplans,
                directionalComparitor(orderDirection, orderBy),
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((careplan: CareplanTemplateInterface, index: number) => {
                  return careplanRow(
                    careplan,
                    `careplan-row-${index}`,
                    history,
                  );
                })
            ) : (
              <PlaceholderRow loading={loading} colSpan={9} />
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE}
        component="div"
        count={careplansLength}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => handlePagination(newPage, false, 0)}
        onRowsPerPageChange={(e) => {
          handlePagination(0, true, parseInt(e.target.value, 10));
        }}
      />
    </StyledPaper>
  );
};

export default CareplanTable;
